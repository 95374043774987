<template>
  <el-container>
    <el-main class="left">
      <div class="order-info">
        <div class="order-info-top">
          <div class="title">订单信息</div>
          <el-tag size="small" style="margin-left: 20px; background-color: green; color: #fff">{{ refund.order.type == 1 ? '平台服务' : refund.order.type == 2 ? '物业服务'  : refund.order.type == 3 ? '技工服务' : '--' }}</el-tag>
        </div>
        <div class="content">
          <ul class="order-num">
            <li>
              退款单号：
              <span>{{ refund.refund_no }}</span>
            </li>
             <li>
              订单编号：
              <span>{{ refund.order.order_sn }}</span>
            </li>
             <li>
              提交时间：
              <span>{{ getDateformat(refund.create_time)}}</span>
            </li>
          </ul>
          <div class="handle-progress" v-if="progress_status1" >
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">1</div>
              <p class="handle-text" style="color: #409eff">申请退款</p>
              <p class="handle-time">{{ getDateformat(refund.create_time) }}</p>
            </div>
            <div class="line" style="background: #409eff;" ></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">2</div>
              <p class="handle-text" style="color: #409eff">退款关闭</p>
              <p class="handle-time">{{ getDateformat(refund.create_time) }}</p>
            </div>
          </div>

          <div class="handle-progress" v-if="!progress_status1">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">1</div>
              <p class="handle-text" style="color: #409eff">申请退款</p>
              <p class="handle-time">{{ getDateformat(refund.create_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd" v-if="refund.status==30" >
              <div class="num" style="background: #409eff; color: #fff">2</div>
              <p class="handle-text" style="color: #409eff">技工驳回</p>
              <p class="handle-time">{{ getDateformat(refund.create_time) }}</p>
            </div>
            <div class="line" v-if="refund.status==30"></div>
            <div class="item" style="background: #ddddfd" v-if="refund.status==60">
              <div class="num" style="background: #409eff; color: #fff">3</div>
              <p class="handle-text" style="color: #409eff">物业驳回</p>
              <p class="handle-time">{{ getDateformat(refund.create_time) }}</p>
            </div>
            <div class="line" v-if="refund.status==60" ></div>
            <div class="item" style="background: #ddddfd" :style="{ background: (refund.status==20 || refund.status==50 || refund.status==80 ) ? '#ddddfd' : '' }">
              <div class="num" style="background: #409eff; color: #fff">4</div>
              <p class="handle-text" style="color: #409eff">退款成功</p>
            </div>
          </div>

          <div class="handle-status">
            <div class="status" v-if="refund.status == 2" style="color: #e6a23c">

            </div>
            <div class="status" v-else>订单状态： {{
                refund.status == 0 ? '用户取消' :
                 refund.status == 10 ? '待处理' :
                  refund.status == 20 ? '技师同意' :
                   refund.status == 30 ? '技师驳回' :
                    refund.status == 40 ? '申请物业介入' :
                     refund.status == 50 ? '物业同意 ' :
                      refund.status == 60 ? '物业拒绝' :
                         refund.status == 70 ? '申请平台' :
                            refund.status == 80 ? '平台同意' :
                               refund.status == 90 ? '平台拒绝' :
                               '--'
                }}</div>
              <div style="margin-top:20px;">  <el-button v-if="refund.status == 40" @click="checkStatus" type="primary" size="small">处理</el-button>  </div>
          </div>
        </div>
      </div>
      
      <div class="buyers-info"  v-for="(item) in refund.log" :key="item.id">
        <div class="title">{{ item.identity_type  == 1?'技工':item.identity_type  == 2?'物业':item.identity_type  == 3?'平台':'--'}} {{item.status==1?'同意':'驳回'}}</div>
        <div class="content contentColum">
          <div style="width:100%">
            <div style="display: flex;flex-direction: row;width: 100%">
            <p>
              {{item.status==1?'同意':'驳回'}}时间：
              <span>{{getDateformat(item.create_time)}}</span>
            </p>
              <p>
                     {{item.status==1?'同意':'驳回'}}账号：
                    <span>{{ item.operation_name }} </span>
              </p>
              <p>
                   <!--物业电话：
                    <span></span>-->
              </p>
            </div>
            <p>
              {{item.status==1?'同意':'驳回'}}说明：
              <span>{{ item.reason }}</span>
            </p>
             <p>
              上传图片：
              <ul>
                  <li  v-for="(item,index) in item.images_d" :key="index">
                    <el-image style="width: 50px; height: 50px" :src="item" :preview-src-list="item.images_d"></el-image>
                  </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div class="buyers-info">
        <div class="title">维权信息</div>
        <div class="content contentColum">
           <div style="width: 100%">
              <div style="display: flex;flex-direction: row;width: 100%">
            <p>
              申请时间：
              <span>{{ getDateformat(refund.create_time) || '--' }}</span>
            </p>
            <p>
              处理方式：
              <span> 退款 </span>
            </p>
            <p>
              退款金额：
              <span>{{ refund.refund_money }}</span>
            </p>
            </div>
            <p>
              退款说明：
              <span>{{ refund.description }}</span>
            </p>
             <p>
              上传图片：
               <ul>
                  <li  v-for="(item, index) in refund.images_d" :key="index">
                    <el-image style="width: 50px; height: 50px" :src="item" :preview-src-list="refund.images_d"></el-image>
                  </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

<!-- 小卡片 -->
<el-dialog title="平台处理" :visible.sync="deal" width="40%">
<ul class="cardUl">
  <li>
    <div class="cardLi">
      <div class="content cardLiTab">
        <el-form ref="form" :model="form" label-width="130px" label-position="left">
        <el-form-item label="处理方式：">
            退款
        </el-form-item>
         <el-form-item label="退款金额">
            {{refund.refund_money}}
        </el-form-item>
        <el-form-item label="处理结果">
                  <el-radio-group v-model="form.status">
                    <el-radio label="1">同意退款</el-radio>
                    <el-radio label="-1">驳回申请</el-radio>
                  </el-radio-group>
        </el-form-item>

        <el-form-item v-if="form.status== -1" label="驳回原因:">
          <el-input type="textarea" v-model="form.reason"></el-input>
        </el-form-item>
        <el-form-item v-if="form.status== -1" label="上传图片：" class="item" >
        <ReadyUploadSource @getSource="getSource" @changeOrder="list => (form.images = list)"
        @removeThis="index => form.images.splice(index, 1)" :isMany="true"
        :manyPath="form.images" :isManyMax="10" :maxSize="1024 * 1024"></ReadyUploadSource>
         <p class="tips">建议图片尺寸800*800px，大小不超过1M，最多可以上传10张图片，其中第一张为主图（有主图视频除外）</p>
        </el-form-item>
        <el-form-item class="formBtn">
          <el-button @click="deal = false">取消</el-button>
          <el-button type="primary" @click="onSubmit">确定</el-button>
        </el-form-item>
      </el-form>
  </div>
</div>
</li>
</ul>
</el-dialog>


      <div class="buyers-info">
        <div class="title">买家信息</div>
        <div class="content contentColum">
            <div style="width: 100%">
              <div style="display: flex;flex-direction: row;width: 100%">
           <p>
              下单人账号：
              <span>{{ info.name || '--' }}</span>
            </p>
            <p>
              下单人姓名：
              <span>{{ info.name || '--' }} </span>
            </p>
            <p>
              下单人电话：
              <span>{{ info.mobile }}</span>
            </p>
          </div>
          </div>
           <!-- <div v-if="!info.extend_order_extend.form_id">
            <p>
              下单人电话：
              <span>{{ info.extend_order_extend.reciver_infos.phone }}</span>
            </p>
          </div> -->
        </div>
      </div>
      <div class="goods-info">
        <div class="title">服务信息</div>
        <div class="content">
          <el-table :data="info.extend_order_goods" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <el-table-column  label="服务信息" align="center" width="300px">
              <template >
                <div class="goods-title">
                  <el-image :src="info.service_cover_picture"></el-image>
                  <p>
                    {{ info.service_name }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="sku_list.title" label="服务类别" align="center">
              <template >
                  {{info.service_cate_name}}
              </template>
            </el-table-column>
            <el-table-column prop="goods_price" label="自定义规格" align="center">
              <template >{{info.service_attr_str}}</template>
            </el-table-column>
          </el-table>
          <el-table :data="info.extend_order_goods" :header-cell-style="{ 'background-color': '#F8F9FA' }" style="margin-top: 20px">
            <el-table-column prop="goods_img" label="下单人" align="center" width="300px">
              <template >
                <div class="goods-title">
                  <p>
                    {{ info.name }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="sku_list.title" label="下单人电话" align="center">
              <template>
                  {{info.mobile}}
              </template>
            </el-table-column>
            <el-table-column prop="goods_price" label="预约时间" align="center">
              <template>{{getDateformat(info.order_time)}}</template>
            </el-table-column>
            <el-table-column prop="goods_num" label="服务区域" align="center"></el-table-column>
            <el-table-column prop="goods_num" label="服务详细地址" align="center">
              <template>{{info.address_info_d.address}}{{info.address_info_d.door_no}}</template>
            </el-table-column>
          </el-table>
          <div class="currentStation">
            <div class="currentPic">
              <span>现场图片</span>
             <ul style="display: flex">
                  <li  v-for="(item, index) in info.images_d" :key="index" style="margin-right: 10px">
                    <el-image style="width: 50px; height: 50px" :src="item" :preview-src-list="info.images_d"></el-image>
                  </li>
              </ul>
            </div>
            <div class="currentDesc">
              <span>描述</span>
              <p>{{info.description}}</p>
            </div>
          </div>
          
        </div>
      </div>
      <div class="goods-info" style="margin-bottom: 20px">
        <div class="title">接单信息</div>
        <div class="content">
          <el-table :data="info.extend_order_goods" :header-cell-style="{ 'background-color': '#F8F9FA' }" style="margin-top: 20px">
            <el-table-column prop="goods_img" label="接单技工姓名" align="center" width="300px">
              <template >
                <div class="goods-title">
                  <p>
                      {{ info.artisan_data?info.artisan_data.artisan_name:'--' }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column  label="接单技工电话" align="center">
              <template>
                     {{ info.artisan_data.mobile?info.artisan_data.mobile:'--' }}
              </template>
            </el-table-column>
            <el-table-column  label="完成照片" align="center">
                <ul style="display: flex">
                  <li  v-for="(item, index) in info.mechanic_finish_image_d" :key="index" style="margin-right: 10px">
                    <el-image style="width: 50px; height: 50px" :src="item" :preview-src-list="info.mechanic_finish_image_d"></el-image>
                  </li>
              </ul>
            </el-table-column>
          </el-table>
        </div>
      </div>
       <div class="goods-info">
        <div class="title">支付信息</div>
        <div class="content">
          <el-table :data="info.extend_order_goods" :header-cell-style="{ 'background-color': '#F8F9FA' }" style="margin-top: 20px">
            <el-table-column prop="goods_img" label="定金金额" align="center" width="300px">
              <template >
                <div class="goods-title">
                  <p>
                      {{ info.deposit_price }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column  label="定金支付方式" align="center">
              <template>
                  {{ info.deposit_pay_type ==10 ? '微信支付' : info.deposit_pay_type ==20 ?  '支付宝支付' : info.final_pay_type ==40 ?  '余额支付' : '--' }}
              </template>
            </el-table-column>
            <el-table-column  label="定金支付时间" align="center">
              <template>
                  {{ info.deposit_pay_time?getDateformat(info.deposit_pay_time):''}}
              </template>
            </el-table-column>
            <el-table-column  label="尾款金额" align="center">
              <template>
                  {{ info.final_total_price }}
                <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    content="
                        工时费:2,
                        工作时间:1">
                  <el-button slot="reference" size="mini" type="text">
                    <i class="el-icon-question"></i>
                  </el-button>
                  <div style="text-align: center; margin: 0">
                     <p>工时费:{{info.service_data.one_hour_cost}}</p>
                     <p>工作时间:{{ info.mechanic_time }}</p>
                     <p>工作费用: {{info.service_data.one_hour_cost}}  * ( {{ info.mechanic_time }} - {{ info.vip_no_hour_cost }} )</p>
                     <p>
                        维修费用清单:
                        <ul v-for="(item, index) in info.mechanic_material_d" :key="index">
                           <li>名称: {{item.name}} 价格: {{item.price}} 数量: {{item.num}}</li>
                        </ul>
                     </p>
                     <p>尾款: {{ info.final_pay_price }}</p>
                     <p>优惠券减免: {{ info.fianl_reduce_price }}</p>
                  </div>
                </el-popover>

              </template>
            </el-table-column>
            <el-table-column  label="尾款支付方式" align="center">
              <template>
                  {{ info.final_pay_type ==10 ? '微信支付' : info.final_pay_type ==20 ?  '支付宝支付' :info.final_pay_type==40 ? '余额支付' : '--' }}
              </template>
            </el-table-column>
            <el-table-column  label="尾款支付时间" align="center">
              <template>
                  {{getDateformat(info.final_pay_time)}}
              </template>
            </el-table-column>
            <el-table-column  label="总计金额" align="center">
              <template>
                  {{ info.total_price }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import ReadyUploadSource from '@/components/readyUploadSource';
import { mapState } from 'vuex';
export default {
  components: {
    ReadyUploadSource,
  },
  data() {
    return {
      id: '',
      info: {
        address_info_d:{},
        artisan_data:{},
        service_data:{},
      },
      refund: {
        order:{}
      },
      goods_info: [],
      logisticsInfo: '',
      deal:false,
      showMessage: !1,
      showOrderSend: !1,
      express_id: '', //快递公司
      tracking_no: '', //快递单号
      company_name: '',
      tracking_no1: '',
      orderSendType: 1,
      progress_status1: 0,
      progress_status2: 0,
      progress_status3: 0,
      progress_status4: 0,
      form:{
        images:[],
      },
      forminfo: null,
      // 图片
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: ['https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg', 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'],
    };
  },
  computed: {
    // ...mapState({
    //   logisticsList: state => state.order.logisticsList, //物流公司列表
    // }),
  },
  watch: {
    // showOrderSend(val) {
    //   if (val && !this.logisticsList.length) this.$store.dispatch('order/getLogistics', { rows: 10000 });
    // },
  },
  created() {
    // let utils = this.$store.state.utils;
    // utils.page = Number(this.$route.query.page);
    // utils.rows = Number(this.$route.query.rows);
     this.id = this.$route.query.id;
     this.getOrderInfo();  
  },
  methods: {
    checkStatus(){
      this.deal=true
    },
    onSubmit(){
        let data = this.form
          data.id= this.id
        this.$axios.post(this.$api.service.orderRefundDeal, data).then(res => {
            if (res.code == 0) {
                this.getOrderInfo();
            }
        });
    },
    // 获取组件中选中的图片
    getSource (list) {
        list.map(item => {
            this.form.images.push(item.path);
        });
    },
    handleSales(val) {
      this.handle_state = val;
      this.showHandleSales = !0;
    },
    getOrderInfo() {
      this.$axios.post(this.$api.service.orderRefundInfo, { id: this.id }).then(res => {
        if (res.code == 0) {
          let info = res.result;
          let stateArr = [10];
          // if (info.payment_time) stateArr.push(20);
          // if (info.cancel_time) stateArr.push(0);
          // if (info.extend_order_extend.tracking_time) stateArr.push(30);
          // if (info.finnshed_time) stateArr.push(40);
          // if (info.evaluate_time) stateArr.push(50);
          if (info.status == 0 ) this.progress_status1 = 1;
          //if ([0, -10,-20].sort().toString() == stateArr.sort().toString()) this.progress_status1 = 1;
          console.log(this.progress_status1);
          // if ([0, 10, 20].sort().toString() == stateArr.sort().toString()) this.progress_status2 = 1;
          // if ([0, 10, 20, 30].sort().toString() == stateArr.sort().toString()) this.progress_status3 = 1;
          // if ([0, 10, 20, 30, 40].sort().toString() == stateArr.sort().toString()) this.progress_status4 = 1;
          this.refund = info;
          this.info = info.order;
          this.info.extend_order_goods = [{id:1}];
 
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  font-family: Microsoft YaHei;
  font-size: 14px;
  color: #17171a;
}

.order-info-top {
  display: flex;
}

.title {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 20px;
}
.left {
  margin-right: 8px;
  background: #fff;
  .order-info {
    margin-bottom: 30px;
  }
  .order-info .content {
    .order-num {
      display: flex;
      height: 47px;
      background: #f0f0f5;
      align-items: center;
      padding-left: 24px;
      li {
        font-size: 14px;
        margin-right: 40px;
        span {
          font-weight: 400;
          color: #17171a;
        }
      }
    }
    .handle-progress {
      display: flex;
      justify-content: center;
      height: 190px;
      align-items: center;
      .line {
        height: 4px;
        width: 120px;
        background: #f0f0f5;
      }

      .item {
        background: #fff;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .num {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #c8c8cc;
          border-radius: 50%;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
        .handle-text {
          width: 100px;
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -25px;
          font-size: 14px;
          font-weight: bold;
          color: #969699;
        }
        .handle-time {
          width: 120px;
          position: absolute;
          left: 50%;
          text-align: center;
          transform: translateX(-50%);
          bottom: -25px;
          font-size: 12px;
          font-weight: 400;
          color: #969699;
        }
      }
    }
    .handle-status {
      padding: 30px 0;
      background: #f0f0f5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .status {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #969699;
      }
      .btn {
        margin-top: 18px;
      }
      .order-actions {
        margin-top: 10px;
      }
    }
  }
  .buyers-info {
    margin-bottom: 30px;
    .content {
      margin-top: 24px;
      padding-left: 40px;
      div {
        display: flex;
        span {
          font-weight: 400;
        }
        p {
          width: 240px;
          &:last-child {
            width: auto;
          }
        }
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 8px;
        }
      }
    }
  }
  .goods-info .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      margin-top: 20px;
      border-bottom: 1px solid #f5f5fa;
      color: #969699;
      span:last-child {
        color: #17171a;
        width: 80px;
        text-align: right;
      }
      p {
        display: flex;
        margin-bottom: 5px;
      }
    }
    .goods-title {
      display: flex;
      justify-content: center;
      align-items: center;
      .el-image {
        width: 68px;
        height: 68px;
        border-radius: 8px;
        margin-right: 8px;
      }
      p {
        flex: 1;
      }
    }
    .total-price {
      font-weight: bold;
      margin-top: 5px;
      display: flex;
      span:last-child {
        text-align: right;
        font-weight: 400;
        color: #fd5e94;
        width: 80px;
      }
    }
  }
}

.logistics-address {
  width: 480px;
  background: #fff;
  overflow: hidden;
  .sn {
    margin-bottom: 30px;
  }
  /deep/ .el-timeline-item__content {
    color: #17171a;
  }
}

.formdata {
  .row {
    display: flex;
    line-height: 36px;
    .row_left {
      width: 160px;
      text-align: right;
      flex-shrink: 0;
    }
    .row_right {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      .el-image {
        height: 76px;
        width: auto;
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
}

.currentStation {
  width: 100%;
  height: 200px;
  color: gray;
  margin-top: 20px;
}

.currentStation > div {
  height: 50%;
  display: flex;
  align-items: center;
}

.currentStation > div > span:nth-child(1) {
  width: 150px;
}

.contentColum {
  div {
    display: flex;
    flex-direction: column;
  }

  p {
    margin-bottom: 50px;
  }

  div {
    width: 100px;
    margin-top: 20px;
  }

  ul {
    display: flex;

    li {
      margin-right: 10px;
    }
  }
}
</style>
